import React, { ChangeEvent, useState } from "react";
import {
  TextInput,
  DatePicker,
  SelectInput,
  SimpleFileUpload,
  SearchDropDown,
} from "@simplecitizen/gdl-react-ui-components";
import { Button } from "../../../../../components/lib/button/Button";
import { countries } from "../../../../../utils/countries";
import { useAppDispatch } from "src/hooks/redux-hooks";
import { addDoc } from "../../../../../store/slices/uploadedNonIdDocsSlice";
import axios, { AxiosProgressEvent } from "axios";
import { uploadNonIdentityDocument } from "src/services/apiService";
import {
  formatDate,
  isValidDate,
  universalDateFormatter,
} from "src/utils/utils";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://localhost:5003/api/workright";

interface UploadedImage {
  format: string;
  key: string;
  source: string;
}

interface ApiErrorResponse {
  title: string;
  status: number;
  document: null | string;
  errors: {
    [key: string]: {
      [lang: string]: string;
    };
  };
  warnings: {};
}

const NonIdentityCollectorForm = ({
  selectedDocument,
  setDocumentCollectors,
  closeModal,
  checkId,
}: {
  closeModal: () => void;
  checkId: string;
  selectedDocument: DocumentCollector | null;
  setDocumentCollectors: React.Dispatch<
    React.SetStateAction<DocumentCollector[] | null>
  >;
}) => {
  const [fileUploadError, setFileUploadError] = useState<string | null>(null);
  const [dateInputValue, setDateInputValue] = useState(
    selectedDocument?.nonIdentityDocuments &&
      selectedDocument.nonIdentityDocuments.documentExpiryDate
      ? selectedDocument.nonIdentityDocuments.documentExpiryDate
      : ""
  );
  const [issueCountyInputValue, setCountryInputValue] = useState(
    selectedDocument?.nonIdentityDocuments &&
      selectedDocument.nonIdentityDocuments.documentIssueCountry
      ? selectedDocument.nonIdentityDocuments.documentIssueCountry
      : ""
  );
  const [documentNumber, setDocumentNumber] = useState<string>(
    selectedDocument?.nonIdentityDocuments &&
      selectedDocument.nonIdentityDocuments.documentNumber
      ? selectedDocument.nonIdentityDocuments.documentNumber
      : ""
  );
  const [documentExpiryDate, setDocumentExpiryDate] = useState<Date | string>(
    selectedDocument?.nonIdentityDocuments &&
      selectedDocument.nonIdentityDocuments.documentExpiryDate
      ? new Date(
          universalDateFormatter(
            selectedDocument.nonIdentityDocuments.documentExpiryDate
          )
        )
      : ""
  );

  const [submitting, setIsSubmitting] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const [documentIssueCountry, setDocumentIssueCountry] = useState<string>(
    selectedDocument?.nonIdentityDocuments &&
      selectedDocument.nonIdentityDocuments.documentIssueCountry
      ? selectedDocument.nonIdentityDocuments.documentIssueCountry
      : ""
  );

  const [uploadedImage, setUploadedImage] = useState<UploadedImage | null>(
    selectedDocument?.nonIdentityDocuments &&
      selectedDocument.nonIdentityDocuments.uploadedServerDocument
      ? selectedDocument.nonIdentityDocuments.uploadedServerDocument
      : null
  );

  const [uploadedFile, setUploadedFile] = useState<File | null>(
    selectedDocument &&
      selectedDocument.nonIdentityDocuments &&
      selectedDocument.nonIdentityDocuments.documentFile
      ? (selectedDocument.nonIdentityDocuments.documentFile as unknown as File)
      : null
  );

  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [serverErrors, setServerErrors] = useState<string[] | null>([]);
  const [doneOploading, setDoneUploading] = useState<boolean>(false);

  const handleFileChange = async (files: File[]): Promise<void> => {
    try {
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("key", "front");
      setDoneUploading(false);
      const config = {
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / (progressEvent.total ?? 0)) * 100
          );
          setUploadProgress(progress);
        },
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${API_BASE_URL}/v1/checks/${checkId}/document-collectors/${selectedDocument?._id}/images`,
        formData,
        config
      );
      setUploadedFile(files[0]);
      setUploadedImage(data);
      setFileUploadError(null);
    } catch (error) {
      console.error("An error occurred while uploading the file", error);
    } finally {
      setDoneUploading(true);
    }
  };

  const fields = [
    {
      name: "document_number",
      fieldType: "text",
      label: "Document number",
      value: documentNumber,
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        // Allow only alphanumeric characters (a-z, A-Z, 0-9)
        const alphanumericValue = value.replace(/[^a-zA-Z0-9]/g, "");
        setDocumentNumber(alphanumericValue);
      },
    },
    {
      name: "document_expiry_date",
      fieldType: "date",
      label: "Document expiry date",
      value: documentExpiryDate,
      onDateChange: (e: Date) => {
        setDocumentExpiryDate(e);
        setDateInputValue(e.toLocaleDateString());
      },
    },
    {
      name: "document_issue_country",
      fieldType: "search-dropdown",
      label: "Document issue country",
      value: documentIssueCountry !== "" ? documentIssueCountry : undefined,
      onSelect: (e: Option) => {
        setDocumentIssueCountry(e.label);
        setCountryInputValue(e.label);
      },
      options: countries,
    },
  ];

  const handleApiErrors = (errorResponse: ApiErrorResponse) => {
    const errorMessages = Object?.values(errorResponse?.errors)?.flatMap(
      (errorDetail) => Object?.values(errorDetail)
    );
    setServerErrors(errorMessages);
  };

  const onSubmit = async () => {
    // if (uploadProgress > 0 && uploadProgress !== 100) return;
    if (!uploadedFile) {
      setFileUploadError("Please upload a document");
      return;
    }
    if (submitting) return;
    setServerErrors([]);

    const dataToSend = [
      {
        slug: "document-number",
        name: {
          en: "Document Number",
        },
        value: documentNumber,
        source: "workright-employee-experience",
      },
      {
        slug: "date-of-expiry",
        name: {
          en: "Date of Expiry",
        },
        value:
          typeof documentExpiryDate === "string"
            ? ""
            : documentExpiryDate?.toLocaleDateString(),
        source: "workright-employee-experience",
      },
      {
        slug: "issuing-state-name",
        name: {
          en: "Issuing State name",
        },
        value: documentIssueCountry,
        source: "workright-employee-experience",
      },
    ];
    const imagesToSend = [uploadedImage];
    setIsSubmitting(true);
    try {
      const uploadedDocuments = await uploadNonIdentityDocument(
        selectedDocument?._id!,
        checkId,
        {
          data: dataToSend,
          images: imagesToSend,
        }
      );

      const data: NonIdentityDocument = {
        documentNumber: documentNumber,
        documentExpiryDate:
          typeof documentExpiryDate === "string"
            ? ""
            : dateInputValue.trim() === ""
              ? ""
              : formatDate(documentExpiryDate),
        documentIssueCountry:
          issueCountyInputValue.trim() === "" ? "" : documentIssueCountry,
        documentFile: uploadedFile,
        docId: uploadedDocuments._id,
        uploadedServerDocument: uploadedDocuments.images[0],
      };

      setDocumentCollectors((prev) => {
        if (prev) {
          return prev.map((doc) => {
            if (doc._id === selectedDocument?._id) {
              return {
                ...doc,
                nonIdentityDocuments: data,
              };
            }
            return doc;
          });
        }
        return null;
      });
      dispatch(addDoc({ ...selectedDocument, doc_key: uploadedDocuments._id }));

      closeModal();
    } catch (error: any) {
      handleApiErrors(error);
      console.log({ error });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="md:w-full">
      {fields &&
        fields.map((field) => {
          switch (field.fieldType) {
            case "text":
              return (
                <TextInput
                  className="py-1"
                  inputClassName="py-0"
                  label={field.label}
                  name={field.name}
                  labelClassName="font-normal"
                  onChange={field.onChange}
                  type="text"
                  pattern="[a-zA-Z0-9]/g"
                  value={field.value}
                />
              );
            case "date":
              return (
                <DatePicker
                  onChange={(e: any) => setDateInputValue(e.target.value)}
                  onError={() => setDateInputValue("")}
                  datePickerClassName="top-[4rem]"
                  className="-mt-2"
                  inputClassName="py-1"
                  label={field.label}
                  name={field.name}
                  onDateChange={field.onDateChange!}
                  startWithTodaysDate={
                    selectedDocument?.nonIdentityDocuments &&
                    selectedDocument.nonIdentityDocuments.documentExpiryDate
                      ? true
                      : false
                  }
                  value={
                    isValidDate(field?.value?.toString() || "")
                      ? (field.value as Date)
                      : new Date()
                  }
                  labelClassName="font-normal"
                />
              );
            case "select":
              return (
                <SelectInput
                  label={field.label}
                  inputClassName="py-0"
                  className="py-0"
                  name={field.name}
                  options={field.options}
                  onChange={field.onChange}
                  value={field.value}
                  labelClassName="font-normal"
                />
              );
            case "search-dropdown":
              return (
                <SearchDropDown
                  onChange={(e: any) => setCountryInputValue(e.target.value)}
                  onClear={() => setCountryInputValue("")}
                  onError={() => setCountryInputValue("")}
                  initialValue={field.value}
                  resultsContainerClassName="top-[4rem]"
                  inputClassName="py-1"
                  label={field.label}
                  name={field.name}
                  className="-mt-2"
                  labelClassName="font-normal"
                  options={field.options}
                  onSelect={field.onSelect}
                />
              );
            default:
              return (
                <TextInput
                  label={field.label}
                  name={field.name}
                  labelClassName="font-normal"
                />
              );
          }
        })}
      <div className="mt-4 mb-8">
        <SimpleFileUpload
          onFileSelect={handleFileChange}
          accept=".jpg,.jpeg,.png,.pdf"
          className="cursor-pointer"
          placeholder={uploadedFile ? uploadedFile.name : undefined}
          error_message={fileUploadError ? fileUploadError : undefined}
          // className=" bg-[#243745ba] !text-white "
        />

        {uploadProgress > 0 && uploadProgress !== 100 && !doneOploading && (
          <div className="flex items-center justify-center mt-2">
            <p className="text-wr_blue text-sm font-normal leading-4 text-left">
              Uploading... {uploadProgress}%
            </p>
          </div>
        )}

        {serverErrors && serverErrors.length > 0 && (
          <div className="text-red-600 bg-red-400/40 text-left px-2 py-2 rounded mt-4">
            {serverErrors.map((error) => (
              <p>{error}</p>
            ))}
          </div>
        )}
      </div>

      <Button
        type="button"
        onClick={onSubmit}
        label={submitting ? "Saving..." : "Save"}
        size="w-full"
      />
    </div>
  );
};

export default NonIdentityCollectorForm;
