import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faSpinner,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import { SelfieCapture } from "../identity-document-selfie-capture/SelfieCapture.tsx";

import { IdentityDocumentReader } from "../identify-document-reader/IdentifyDocumentReader.tsx";
import { IdentityDocumentReview } from "../identity-document-review/IdentityDocumentReview.tsx";
import { useSelector } from "react-redux";
import {
  addIdentityDoc,
  setDocCaptureData,
} from "../../../../store/slices/identityDocCapture.ts";
import { IdentityComparisonMismatch } from "../identity-comparison-mismatch/IdentityComparisonMismatch.tsx";
import { ImageKey } from "../../../models/image-key.enum.ts";
import axios from "axios";
import { useParams } from "react-router-dom";
import {useAppDispatch} from "src/hooks/redux-hooks.ts";

export interface IdentityDocumentUploadStepsProps {
  identityDocumentId?: string;
  setDocumentCollectors?: React.Dispatch<
    React.SetStateAction<DocumentCollector[] | null>
  >;
  documentCollectorId?: string;
  onDocumentStored: (uploadedDocumentId: string) => void;
  onUploadCancelled: () => void;
  isDoneProcess?: any;
}

export const IdentityDocumentUploadSteps = ({
  documentCollectorId,
  identityDocumentId,
  onUploadCancelled,
  onDocumentStored,
  isDoneProcess,
  setDocumentCollectors,
}: IdentityDocumentUploadStepsProps) => {
  const [identityDocument, setIdentityDoc] = useState<any>();
  const [warnings, setWarnings] = useState<any>();
  const [errors, setErrors] = useState<any>();
  const [uploadError, setUploadError] = useState<any>();
  const [isDocumentReaderOpen, setIsDocumentReaderOpen] = useState(true);
  const [isSelfieCaptureOpen, setIsSelfieCaptureOpen] = useState(false);
  const [isDocumentReviewOpen, setIsDocumentReviewOpen] = useState(false);
  const [isFacialMismatchOpen, setIsFacialMismatchOpen] = useState(false);
  const [frontSideImageUrl, setFrontSideImageUrl] = useState("");
  const [selfieImageUrl, setSelfieImageUrl] = useState("");
  const [isUpdatingDocument, setIsUpdatingDocument] = useState(false);
  const [errorMessage] = useState("");
  const [documentRequirement, setDocumentRequirement] = useState<any>();
  const [isSelfieRequired, setIsSelfieRequired] = useState<boolean>();
  const [frontSideImageData, setFrontSideImageData] = useState<any>(null);
  const [backSideImageData, setBackSideImageData] = useState<any>(null);
  const [selfieImageData, setSelfieImageData] = useState<any>(null);
  const [currentUploadAttempt, setCurrentUploadAttempt] = useState<number>(0);
  const [showHavingIssuesLink, setShowHavingIssuesLink] =
    useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);
  const { checkId } = useParams<{ checkId: string }>();
  const [selectedIDDoc, setSelectedIDDoc] = useState<DocumentCollector>();

  const base64ImagePrefix = "data:image/png;base64,";

  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL ||
    "https://localhost:5003/api/workright";

  const MAX_UPLOAD_ATTEMPTS = parseInt(
    process.env.REACT_APP_MAX_UPLOAD_ATTEMPTS || "3",
    10
  );

  const dispatch = useAppDispatch();

  const documentCollector = useSelector(
    (state: any) => state?.identity_doc_capture?.getDocumentCollector ?? ""
  );

  const selectedIDDocument = useSelector(
    (state: any) => state?.identity_doc_capture?.selectedIDDocument
  );

  const [regulaData, setRegulaData] = useState({});
  useEffect(() => {
    if (selectedIDDocument != null) {
      setSelectedIDDoc(selectedIDDocument);
    }
  }, [selectedIDDoc, selectedIDDocument]);

  useEffect(() => {
    setDocumentRequirement(selectedIDDocument);

    setIsSelfieRequired(selectedIDDocument?.config?.facialComparison);

    if (isDone) {
      isDoneProcess(isDone);
    }
  }, [
    documentCollectorId,
    documentRequirement,
    isSelfieRequired,
    isDocumentReaderOpen,
    isDone,
    isDoneProcess,
    dispatch,
    setDocumentRequirement,
    setIsSelfieRequired,
    documentCollector,
    selectedIDDocument,
    identityDocumentId
  ]);

  const updateDocument = (
    frontSideImageData?: any,
    backSideImageData?: any,
    selfieImage?: any
  ) => {
    if (!identityDocumentId) {
      return;
    }

    console.log("UPDATING");

    setIsDocumentReaderOpen(false);
    setIsUpdatingDocument(true);
    setCurrentUploadAttempt((prevAttempt) => prevAttempt + 1);
    if (currentUploadAttempt >= MAX_UPLOAD_ATTEMPTS)
      setShowHavingIssuesLink(true);

    const newImages = [];

    if (frontSideImageData && frontSideImageData !== "") {
      newImages.push({
        format: "base64",
        key: ImageKey.Front,
        source: frontSideImageData
      });
    }

    if (backSideImageData && backSideImageData !== "") {
      newImages.push({
        format: "base64",
        key: ImageKey.Back,
        source: backSideImageData
      });
    }

    if (selfieImage && selfieImage !== "") {
      newImages.push({
        format: "base64",
        key: ImageKey.Selfie,
        source: selfieImage
      });
    }

    dispatch(setDocCaptureData({ attachDocument: newImages }));
    const newDocument = {
      images: newImages
    };

    axios
      .post(
        `${API_BASE_URL}/v1/checks/${checkId}/document-collectors/${selectedIDDocument._id}/Validate`,
        newDocument
      )
      .then((response) => {
        setIsUpdatingDocument(false);
        setIdentityDoc(response.data);

        dispatch(
          addIdentityDoc({
            ...selectedIDDocument,
            doc_key: response.data?._id
          }),
        );
        dispatch(
          setDocCaptureData({
            identityDocument: response.data,
            submittedIDs: {
              [selectedIDDocument._id]: response.data?._id || ""
            },
          }),
        );

        setWarnings({
          warningMessage: "",
        });
        setErrors({
          errorMessage: "",
        });
        setUploadError("");
        setIsDocumentReaderOpen(false);
        setIsDocumentReviewOpen(true);
      })
      .catch((error: any) => {
        const document = error?.response?.data;

        setUploadError(document?.detail);

        setIsUpdatingDocument(false);

        let selfieError = document?.errors?.["images.selfie"]
          ? Object.keys(document.errors["images.selfie"]).length === 0
          : null;

        if (document?.warnings || document?.errors) {
          const unprocessableDocument = document;
          setWarnings(unprocessableDocument?.warnings);
          setErrors(unprocessableDocument?.errors);
          setIdentityDoc(unprocessableDocument?.document);
          dispatch(
            setDocCaptureData({
              identityDocument: unprocessableDocument?.document,
              submittedIDs: {
                [selectedIDDocument?._id]:
                  unprocessableDocument?.document?._id || ""
              },
            }),
          );
        } else {
          setIdentityDoc(document);
          setWarnings({
            warningMessage: "",
          });
          setErrors({
            errorMessage: "",
          });
          dispatch(
            setDocCaptureData({
              identityDocument: document,
              submittedIDs: {
                [selectedIDDocument?._id]: document?._id || ""
              },
            }),
          );
        }

        if (isSelfieRequired && selfieError != null) {
          setIsFacialMismatchOpen(true);
        } else {
          setIsDocumentReaderOpen(false);
          setIsDocumentReviewOpen(true);
        }
      });
  };

  const updateDocumentForceSubmit = (
    frontSideImageData?: any,
    backSideImageData?: any,
    selfieImage?: any
  ) => {
    const newImages = [];

    if (frontSideImageData && frontSideImageData !== "") {
      newImages.push({
        format: "base64",
        key: ImageKey.Front,
        source: frontSideImageData
      });
    }

    if (backSideImageData && backSideImageData !== "") {
      newImages.push({
        format: "base64",
        key: ImageKey.Back,
        source: backSideImageData
      });
    }

    if (selfieImage && selfieImage !== "") {
      newImages.push({
        format: "base64",
        key: ImageKey.Selfie,
        source: selfieImage
      });
    }

    dispatch(setDocCaptureData({ attachDocument: newImages }));
    const newDocument = {
      images: newImages,
      status: "created"
    };

    dispatch(
        setDocCaptureData({
          endpointLoading: true
        }),
    );

    axios
      .post(
        `${API_BASE_URL}/v1/checks/${checkId}/document-collectors/${selectedIDDocument._id}/Validate`,
        newDocument
      )
      .then((response) => {
        dispatch(
          addIdentityDoc({
            ...selectedIDDocument,
            doc_key: response.data?._id
          }),
        );
        dispatch(
          setDocCaptureData({
            identityDocumentForced: response.data,
            submittedIDs: {
              [selectedIDDocument?._id]: response.data?._id
            },
          }),
        );
      })
      .catch((error) => {
        console.log("error:", error)
        const unprocessableDocument = error?.response?.data;
        setIdentityDoc(unprocessableDocument?.document);
        dispatch(
          setDocCaptureData({
            identityDocumentForced: unprocessableDocument?.document,
            submittedIDs: {
              [selectedIDDocument?._id]: unprocessableDocument?.document?._id
            },
          }),
        );
      }).finally(() => {
      dispatch(
          setDocCaptureData({
            endpointLoading: false
          }),
      );
    });
  };



  const onDocumentProcessFinished = (documentImages: any) => {
    const frontImageSource = documentImages?.front?.source;

    if (frontImageSource) {
      // First, update the front side image data
      setFrontSideImageData(frontImageSource);

      // Then, update the front side image URL separately
      setFrontSideImageUrl(base64ImagePrefix + frontImageSource);

      // Handle the rest of the logic after setting the states
      if (selfieImageData === null && isSelfieRequired) {
        setIsSelfieCaptureOpen(true);
        setIsDocumentReaderOpen(false);
      } else {
        setIsDocumentReaderOpen(false);
        updateDocument(frontImageSource, backSideImageData, selfieImageData);
      }
    } else {
      console.warn("Front image source is missing or invalid.");
    }

    setIsDocumentReaderOpen(false);
  };

  const onDocumentReaderClosed = () => {
    setIsDocumentReaderOpen(false);
    onUploadCancelled();
  };

  const onSelfieCaptureFinished = (selfieImageData: any) => {
    setIsSelfieCaptureOpen(false);
    setIsUpdatingDocument(true);
    setSelfieImageData(selfieImageData);
    setSelfieImageUrl(base64ImagePrefix + selfieImageData);
    updateDocument(frontSideImageData, backSideImageData, selfieImageData);
  };

  const onSelfieCaptureModalClosed = () => {
    setIsSelfieCaptureOpen(false);
    onUploadCancelled();
  };

  const retakeSelfie = () => {
    setSelfieImageUrl("");
    setSelfieImageData("");
    setIsFacialMismatchOpen(false);
    setIsSelfieCaptureOpen(true);
  };

  const reuploadDocument = () => {
    setFrontSideImageData("");
    setBackSideImageData("");
    setIsDocumentReviewOpen(false);
    setIsSelfieCaptureOpen(false);
    setIsFacialMismatchOpen(false);
    setIsDocumentReaderOpen(true);
    setIsUpdatingDocument(false);
    //dispatch(removeDoc({ id: selectedIDDoc?._id }));
  };

  const handleForceSubmit = () => {
    updateDocumentForceSubmit(
      frontSideImageData,
      backSideImageData,
      selfieImageData,
    );
    storeDocument();
  };

  const storeDocument = () => {
    if (isDocumentReviewOpen) setIsDocumentReviewOpen(false);
    if (isFacialMismatchOpen) setIsFacialMismatchOpen(false);

    if (identityDocumentId) {
      const uploadedDocData: IdentityDocument = {
        ...regulaData,
        docId: selectedIDDocument?._id,
      };
      updateDocCollectors(uploadedDocData);
      setIsUpdatingDocument(false);
        setIsDone(true);
        onDocumentStored(identityDocumentId);
        dispatch(
            setDocCaptureData({
              identityDocument: identityDocument,
              documentSubmitted: true,
              forcedSubmit: true,
            }),
        );
        dispatch(addIdentityDoc(selectedIDDoc));
    }
  };

  const updateDocCollectors = useCallback(
    (uploadedDocData: {}) => {
      if (setDocumentCollectors) {
        setDocumentCollectors((prev) => {
          if (prev) {
            return prev.map((doc) => {
              if (doc._id === selectedIDDocument._id) {
                return {
                  ...doc,
                  identityDocuments: doc.identityDocuments
                    ? { ...doc.identityDocuments, ...uploadedDocData }
                    : uploadedDocData,
                };
              }
              return doc;
            });
          }
          return null;
        });
      }
    },
    [selectedIDDocument, setDocumentCollectors],
  );

  return (
    <div className="box-border w-full max-h-screen overflow-y-auto flex items-center justify-center">
      {isUpdatingDocument || errorMessage ? (
        <div className="text-center">
          {isUpdatingDocument && (
            <div className="bg-white w-72 sm:w-screen sm:h-[360px] md:w-[640px] md:h-[360px] lg:w-[640px] lg:h-[360px] rounded-2xl py-8 -pt-3">
              <div>
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="text-[10rem] text-deepWater"
                  pulse
                />
              </div>
              <p className="text-3xl font-normal text-center italic p-4">
                Verifying...
              </p>
            </div>
          )}
          {errorMessage && (
            <div className="relative flex items-center justify-center bg-white rounded-2xl w-screen sm:max-w-[640px] py-8 ">
              <div className="close-button absolute top-6 right-6">
                <button type="button" onClick={onUploadCancelled}>
                  <FontAwesomeIcon
                    icon={faTimes}
                    size="xl"
                    style={{ color: "#8C8C8C" }}
                  />
                </button>
              </div>
              <div className="text-base font-semibold text-[#FF4A4A] p-8">
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  size="lg"
                  style={{ padding: "4px" }}
                />
                {errorMessage}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          {isDocumentReaderOpen && (
            <IdentityDocumentReader
              setRegulaData={setRegulaData}
              onDocumentProcessFinished={onDocumentProcessFinished}
              onDocumentReaderClosed={onDocumentReaderClosed}
            />
          )}
          {isSelfieCaptureOpen && !selfieImageUrl && isSelfieRequired && (
            <SelfieCapture
              useFaceLiveness={true}
              onSelfieCaptureFinished={onSelfieCaptureFinished}
              onSelfieCaptureModalClosed={onSelfieCaptureModalClosed}
            />
          )}

          {isFacialMismatchOpen && (
            <IdentityComparisonMismatch
              frontSideImageUrl={frontSideImageUrl}
              selfieImageUrl={selfieImageUrl}
              showHavingIssuesLink={showHavingIssuesLink}
              onReuploadDocument={reuploadDocument}
              onRetakeSelfie={retakeSelfie}
              onForceSubmit={handleForceSubmit}
            />
          )}

          {isDocumentReviewOpen && (
            <IdentityDocumentReview
              identityDocument={identityDocument}
              errors={errors}
              uploadError={uploadError}
              warnings={warnings}
              showHavingIssuesLink={showHavingIssuesLink}
              onReuploadDocument={reuploadDocument}
              onStoreDocument={storeDocument}
              onForceSubmit={handleForceSubmit}
              closeModal={onUploadCancelled}
            />
          )}
        </div>
      )}
    </div>
  );
};
