import { FieldErrors, FieldValues } from "react-hook-form";
import { useEffect, useRef } from "react";
import {
  Title,
  InputField,
  WorkStatus,
  Citizenship,
  DocumentCollectors,
  DataCollectors,
  VevoCheck,
} from "../../../index";
// import { formatString, scrollToElement } from "../../../../utils/utils";
import { StatusCategories } from "./status-categories/StatusCategories";
import { useJurisdiction } from "../../../../hooks/useJurisdiction";
import VevoCheckAdditonalQuestions from "../vevo-check/VevoCheckAdditonalQuestions";
import SuccessCheckmark from "../../success-checmark/SuccessCheckMark";
import { Modal } from "@simplecitizen/gdl-react-ui-components";
import NonIdentityCollectorForm from "./document-collectors/NonIdentityCollectorForm";
import {
  clearIdDocs,
  setDocCaptureData,
} from "../../../../store/slices/identityDocCapture.ts";
import { useDispatch } from "react-redux";
import { clearNonIdDocs } from "src/store/slices/uploadedNonIdDocsSlice.ts";

interface JurisdictionProps {
  jurisdiction: Jurisdiction;
  isVevoError: boolean;
  errors: FieldErrors<FieldValues>;
  formData: FormState;
  register: RegisterFn;
  setShowSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  updateFormValues: (formData?: Partial<FormState>) => void;
  isAustralian: boolean;
  setIsAustralian: React.Dispatch<React.SetStateAction<boolean>>;
  setIsVevoSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDocument?: DocumentCollector | null;
  setSelectedDocument: React.Dispatch<
    React.SetStateAction<DocumentCollector | null>
  >;
  selectedIDDocument?: DocumentCollector | null;
  setSelectedIDDocument: React.Dispatch<
    React.SetStateAction<DocumentCollector | null>
  >;
  isVevoSuccess: boolean;
  checkId?: string;
}

export const Jurisdiction = ({
  jurisdiction,
  isVevoError,
  errors,
  formData,
  register,
  setShowSubmit,
  isAustralian,
  setIsAustralian,
  updateFormValues,
  setIsVevoSuccess,
  setSelectedDocument,
  setSelectedIDDocument,
  selectedDocument,
  selectedIDDocument,
  checkId,
  isVevoSuccess,
}: JurisdictionProps) => {
  const {
    dataCollectors,
    documentCollectors,
    handleDataCollection,
    handleIsCitizen,
    handleRequiredDocs,
    selectStatus,
    resetStatus,
    selectedStatusCategory,
    selectedStatusType,
    showIsCitizenQuestion,
    setDocumentCollectors,
    statusCategories,
    showStatusCategories,
    workStatus,
    citizenship,
    isCitizen,
    // handleVevoCheck,
  } = useJurisdiction({ jurisdiction, setShowSubmit, updateFormValues });
  const isVevoCheck = isAustralian && !showIsCitizenQuestion && !isCitizen;

  // Scrolling behaviour
  const inputFieldRef = useRef<HTMLDivElement>(null);
  const citizenRef = useRef<HTMLDivElement>(null);
  const statusCategoryRef = useRef<HTMLDivElement>(null);
  const workStatusRef = useRef<HTMLDivElement>(null);
  const documentCollectorsRef = useRef<HTMLDivElement>(null);
  const dataCollectorsRef = useRef<HTMLDivElement>(null);
  const vevoRef = useRef<HTMLDivElement>(null);
  // useEffect(() => {
  //   if (vevoRef.current && isVevoSuccess) {
  //     scrollToElement(vevoRef);
  //   }
  //   if (showIsCitizenQuestion && citizenRef.current) {
  //     scrollToElement(citizenRef);
  //   } else if (workStatus && workStatusRef.current) {
  //     scrollToElement(workStatusRef);
  //   } else if (!showIsCitizenQuestion && statusCategoryRef.current) {
  //     scrollToElement(statusCategoryRef);
  //   } else if (dataCollectors && dataCollectorsRef.current) {
  //     scrollToElement(dataCollectorsRef);
  //   } else if (documentCollectors && documentCollectorsRef.current) {
  //     scrollToElement(documentCollectorsRef);
  //   }
  // }, [
  //   showIsCitizenQuestion,
  //   workStatus,
  //   statusCategories,
  //   documentCollectors,
  //   dataCollectors,
  //   isVevoSuccess,
  //   isAustralian,
  // ]);
  const closeModal = () => {
    setSelectedDocument(null);
    setSelectedIDDocument(null);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedDocument !== null) {
      dispatch(setDocCaptureData({ nonIDModalOpen: true }));
    } else {
      dispatch(setDocCaptureData({ nonIDModalOpen: false }));
    }
  }, [dispatch, selectedDocument]);

  useEffect(() => {
    const isDocumentsUploaded = documentCollectors?.some(
      (doc) => doc.nonIdentityDocuments || doc.identityDocuments
    );
    if (documentCollectors && isDocumentsUploaded) {
      if (isDocumentsUploaded) {
        setShowSubmit(true);
      } else {
        setShowSubmit(false);
      }
    }
  }, [documentCollectors, setShowSubmit]);

  return (
    <div className="max-auto flex flex-col gap-1 min-w-full md:min-w-[455px] md:max-w-[455px]">
      {selectedDocument && (
        <Modal
          onClose={closeModal}
          contentContainerClassName="md:!w-[40%] w-full max-h-[90%]"
        >
          <Title
            text={
              selectedDocument.name
                ? `Upload ${selectedDocument.name.en! || "Document"}`.toUpperCase()
                : "Upload Document"
            }
          />
          <NonIdentityCollectorForm
            checkId={checkId!}
            closeModal={closeModal}
            selectedDocument={selectedDocument}
            setDocumentCollectors={setDocumentCollectors}
          />
        </Modal>
      )}
      <Title text="Work Country" />
      <div ref={inputFieldRef}>
        <InputField
          label=""
          name="country"
          error={errors["country"]?.message as string}
          register={register}
          disabled={true}
        />
      </div>

      {showIsCitizenQuestion && (
        <div ref={citizenRef}>
          <Citizenship
            country={formData["country"]}
            handleIsCitizen={(isCitizen: boolean) => {
              handleIsCitizen(isCitizen);
              resetStatus("work");
              resetStatus("category");
              dispatch(clearIdDocs());
              dispatch(clearNonIdDocs());
            }}
            showStatusCategories={showStatusCategories}
            isCitizen={isCitizen}
          />
        </div>
      )}

      {isVevoCheck && (
        <>
          <VevoCheck
            ref={vevoRef}
            isVevoError={isVevoError}
            isVevoSuccess={isVevoSuccess}
            handleValues={updateFormValues}
            formData={formData}
            errors={errors}
            register={register}
          />
        </>
      )}
      {isVevoSuccess && (
        <>
          <div ref={vevoRef} className="flex flex-col items-center">
            <SuccessCheckmark />
            <div className="mt-2 font-bold">VEVO CHECK SUCCESSFUL</div>
          </div>
          {citizenship?.statusTypes && (
            <div ref={workStatusRef} className="mb-6">
              <WorkStatus
                workStatus={citizenship?.statusTypes}
                selectedStatusType={selectedStatusType}
                handleWorkStatus={(status) => selectStatus(null, status)}
                resetWorkStatus={() => resetStatus("work")}
              />
            </div>
          )}
          <VevoCheckAdditonalQuestions
            handleValues={updateFormValues}
            formData={formData}
            errors={errors}
            register={register}
          />
        </>
      )}
      {!isVevoCheck && !isVevoSuccess && (
        <>
          <StatusCategories
            isCitizen={isCitizen}
            showStatusCategories={showStatusCategories}
            showIsCitizenQuestion={showIsCitizenQuestion}
            statusCategories={statusCategories}
            statusCategoryRef={statusCategoryRef}
            selectedStatusCategory={selectedStatusCategory}
            handleStatusCategory={(cat) => selectStatus(cat, null)}
            resetStatusCategory={() => resetStatus("category")}
          />

          {workStatus && (
            <div ref={workStatusRef}>
              <WorkStatus
                workStatus={workStatus}
                selectedStatusType={selectedStatusType}
                handleWorkStatus={(status) => selectStatus(null, status)}
                resetWorkStatus={() => resetStatus("work")}
              />
            </div>
          )}

          {documentCollectors && (
            <div ref={documentCollectorsRef}>
              <DocumentCollectors
                setDocumentCollectors={setDocumentCollectors}
                setSelectedDocument={setSelectedDocument}
                setSelectedIDDocument={setSelectedIDDocument}
                documentCollectors={documentCollectors}
                handleRequiredDocs={handleRequiredDocs}
              />
            </div>
          )}

          {dataCollectors && dataCollectors.length > 0 && (
            <div ref={dataCollectorsRef}>
              <DataCollectors
                dataCollectors={dataCollectors}
                handleDataCollection={handleDataCollection}
                register={register}
                formData={formData}
                errors={errors}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
