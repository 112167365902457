import React, { useEffect, useState } from "react";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";

interface DocumentImage {
  _type: string;
  key: string;
  format: string;
  path: string;
  source: string;
}

interface DocumentData {
  slug: string;
  name: {
    en: string;
    fr: string;
  };
  value: string;
}

export interface IdentityDocument {
  _id: string;
  _type: string;
  _subtype: string;
  data: (
    | DocumentData
    | { slug: string; name: { [languageCode: string]: string }; value: string }
  )[];
  images: DocumentImage[];
  relationships: any[]; // Adjust type as needed
  status: string;
  _updated_at: string;
  _created_at: string;
}

interface ErrorMessages {
  [key: string]: { [lang: string]: string } | string;
}
interface WarningMessages {
  [key: string]: { [lang: string]: string } | string;
}
interface IdentityDocumentReviewProps {
  identityDocument?: any;
  warnings?: WarningMessages;
  errors?: ErrorMessages;
  uploadError?: any;
  showHavingIssuesLink: boolean;
  onReuploadDocument?: () => void;
  onStoreDocument?: () => void;
  onForceSubmit?: () => void;
  closeModal: () => void;
}

export const IdentityDocumentReview = ({
  identityDocument,
  warnings,
  errors,
  uploadError,
  showHavingIssuesLink,
  onReuploadDocument,
  onStoreDocument,
  onForceSubmit,
  closeModal
}: IdentityDocumentReviewProps) => {
  const defaultLanguage = process.env.defaultLanguage || "en";

  const [frontSideImageUrl, setFrontSideImageUrl] = useState<string>("");
  const [backSideImageUrl, setBackSideImageUrl] = useState<string>("");

  const BASE64_IMAGE_URL_PREFIX = "data:image/png;base64,";

  const frontImage = useSelector(
    (state: any) =>
      state?.identity_doc_capture?.documentImages?.front?.source || ""
  );

  const backImage = useSelector(
      (state: any) =>
          state?.identity_doc_capture?.documentImages?.back?.source || ""
  );

  const selectedDoc = useSelector(
    (state: any) => state?.identity_doc_capture?.selectedIDDocument || ""
  );

  useEffect(() => {
    if (frontImage) {
      setFrontSideImageUrl(BASE64_IMAGE_URL_PREFIX + frontImage);
    }
  }, [frontImage]);

  useEffect(() => {
    if (backImage) {
      setBackSideImageUrl(BASE64_IMAGE_URL_PREFIX + backImage);
    }
  }, [backImage]);

  const getFieldNameString = (
    nameData: string | { [languageCode: string]: string },
  ): string => {
    if (typeof nameData === "string") {
      return nameData;
    } else if (nameData && nameData["en"]) {
      // Replace 'en' with your default language
      return nameData["en"];
    } else {
      return "Unknown";
    }
  };

  const getErrorMessage = (
    fieldSlug: React.Key | null | undefined,
    errors: ErrorMessages | undefined,
  ): string | null => {
    if (!errors) {
      return null;
    }

    const key = "data." + fieldSlug;
    const errorMessage = errors[key];

    if (errorMessage) {
      if (typeof errorMessage === "string") {
        return errorMessage;
      } else if (
        typeof errorMessage === "object" &&
        errorMessage[defaultLanguage]
      ) {
        return errorMessage[defaultLanguage];
      }
    }

    return null;
  };

  const getWarningMessage = (
    fieldSlug: React.Key | null | undefined,
    warnings: WarningMessages | undefined,
  ): string | null => {
    const key = "data." + fieldSlug;
    if (warnings && warnings[key]) {
      const warningMessage = warnings[key];
      if (typeof warningMessage === "string") {
        return warningMessage;
      } else if (warningMessage && warningMessage[defaultLanguage]) {
        return warningMessage[defaultLanguage];
      }
    }
    return null;
  };

  const getFrontImageError = (errors?: ErrorMessages): string | null => {
    const error = errors?.["images.front"];
    if (error && typeof error === "object" && "en" in error) {
      return error["en"];
    } else if (typeof error === "string") {
      return error;
    }
    return null;
  };

  const getDocumentTypeError = (errors?: ErrorMessages): string | null => {
    const error = errors?.["data.document_type"];
    if (typeof error === "object" && error !== null && "en" in error) {
      return error["en"];
    }
    return typeof error === "string" ? error : null;
  };

  const getExpectedDocError = (errors?: ErrorMessages): string | null => {
    const error = errors?.["document"];
    if (typeof error === "object" && error !== null && "en" in error) {
      return error["en"];
    }
    return typeof error === "string" ? error : null;
  };

  const frontImageError = getFrontImageError(errors);
  const documentTypeError = getDocumentTypeError(errors || undefined);

  const documentError = getExpectedDocError(errors || undefined);

  const hasErrors = errors?.errorMessage !== "" || uploadError !== "";

  const hasTopLevelErrors = !!errors && !!errors["data.document_type"];

  const requiredSlugs = [
    "given-names",
    "surname-and-given-names",
    "document-number",
    "issuing-state-name",
    "issuing-state-code",
    "date-of-expiry",
  ];

  return (
    <div className="bg-white w-full box-border md:max-w-[45rem] p-6 sm:p-8 md:px-10 md:py-11 rounded-2xl text-deepWater text-base leading-5 font-montserrat flex flex-col gap-3">
      <div className="fixed text-white top-5 right-5 cursor-pointer z-20 bg-[rgba(0,0,0,0.6)] rounded-3xl p-1">
        <button type="button" onClick={closeModal}>
          <IoMdClose className="pt-1 w-5" size={19} />
        </button>
      </div>

      <div className="top-section">
        <h2 className="font-bold font-inter text-lg leading-6 mb-2">{`Upload ${selectedDoc?.name?.en}`}</h2>
        <p className="font-inter">{selectedDoc?.description?.en}</p>
      </div>

      <div className="middle-section flex flex-col items-center md:flex-row md:justify-between md:items-start">
        <div className="images-container flex flex-col gap-2 items-center py-8 px-8 sm:px-40 md:px-4">
          {frontSideImageUrl && (
            <img
              className="w-full md:w-[16rem] md:h-[10rem] object-contain m-auto rounded-lg"
              src={frontSideImageUrl}
              alt="Front Side"
              width={256}
              height={160}
            />
          )}
          {backSideImageUrl && (
            <img
              className="w-full md:w-[16rem] md:h-[10rem] object-contain m-auto rounded-lg"
              src={backSideImageUrl}
              alt="Back Side"
              width={256}
              height={160}
            />
          )}
          {!documentTypeError && frontImageError && (
            <div className="text-base leading-5 w-60 font-inter font-bold text-[#FF4A4A]">
              {frontImageError}
            </div>
          )}
          {identityDocument?.data?.length === 0 && (
            <div className="text-base leading-5 w-60 font-inter font-bold text-[#FF4A4A]">
              Unable to process captured document, please re-upload document
            </div>
          )}
        </div>

        <div className="document-field-list overflow-y-scroll w-full p-4 md:w-[20rem] md:h-[26rem] md:px-6 md:py-5 flex flex-col gap-3 rounded-lg border border-slate-300 shadow-sm">
          {hasTopLevelErrors || !identityDocument ? (
            <>
              {documentTypeError && (
                <div className="text-base leading-5 font-inter font-bold text-[#FF4A4A]">
                  {documentTypeError}
                </div>
              )}
              {documentError && (
                <div className="text-base leading-5 font-inter font-bold text-[#FF4A4A]">
                  {documentError}
                </div>
              )}
            </>
          ) : (
            identityDocument?.data
              ?.filter((field: { slug: string }) =>
                requiredSlugs.includes(field.slug),
              )
              ?.map(
                (field: {
                  slug: React.Key | null | undefined;
                  name: string | { [languageCode: string]: string };
                  value: string | number | readonly string[] | undefined;
                }) => {
                  const errorMessage = getErrorMessage(field.slug, errors);
                  const warningMessage = getWarningMessage(
                    field.slug,
                    warnings,
                  );

                  return (
                    <div key={field?.slug} className="flex flex-col">
                      <label
                        className={`text-base leading-5 font-bold font-inter text-deepWater ${
                          errorMessage
                            ? "text-red"
                            : warningMessage
                              ? "text-yellow"
                              : ""
                        }`}
                      >
                        {getFieldNameString(field.name)}
                      </label>
                      <div
                        className={`flex flex-row items-end font-inter border-b border-deepWater ${
                          errorMessage
                            ? "border-red"
                            : warningMessage
                              ? "border-yellow"
                              : ""
                        }`}
                      >
                        <input
                          className="flex-1 bg-white text-base font-normal font-inter pt-1 text-deepWater"
                          type="text"
                          value={field.value}
                          disabled
                        />
                        {errorMessage && (
                          <span className="text-red stroke-white text-xl font-inter">
                            <FontAwesomeIcon
                              icon={faCircleExclamation}
                              size="sm"
                              style={{ color: "red" }}
                            />
                          </span>
                        )}
                        {warningMessage && (
                          <span className="text-xl font-inter">
                            <FontAwesomeIcon
                              icon={faCircleExclamation}
                              size="sm"
                              style={{ color: "#FFD11B" }}
                            />
                          </span>
                        )}
                      </div>
                      {errorMessage && (
                        <p className="mt-0.5 text-sm font-semibold font-inter text-[#FF4A4A]">
                          {errorMessage}
                        </p>
                      )}
                      {warningMessage && (
                        <p className="mt-0.5 text-sm font-semibold font-inter text-[#FFD11B]">
                          {warningMessage}
                        </p>
                      )}
                    </div>
                  );
                },
              )
          )}
          {uploadError && (
            <div className="text-base leading-5 font-inter font-bold text-[#FF4A4A]">
              {uploadError}
            </div>
          )}
        </div>
      </div>

      <div className="bottom-section py-2 w-full flex flex-col items-stretch gap-2 sm:flex-row sm:justify-end">
        <button
          type="button"
          className="bg-azure hover:bg-mar text-white font-semibold font-inter sm:px-5 py-2 text-center rounded cursor-pointer"
          onClick={onReuploadDocument}
        >
          {"Re-upload Document"}
        </button>
        <button
          type="button"
          onClick={onStoreDocument}
          className={`hover:bg-mar text-white font-semibold font-inter sm:px-5 py-2 text-center rounded ${
            hasErrors
              ? "!bg-gray-400 !cursor-not-allowed"
              : "bg-azure cursor-pointer"
          }`}
          disabled={hasErrors}
        >
          {"Store Document"}
        </button>
      </div>
      {hasErrors && showHavingIssuesLink && !documentError && (
        <div className="flex justify-end text-sm font-inter">
          <button className="underline" onClick={onForceSubmit}>
            {"Having issues? Submit document for review."}
          </button>
        </div>
      )}
    </div>
  );
};
