import React, { useCallback, useEffect, useRef, useState } from "react";

interface ReadMoreProps {
  index: number;
  content: string;
}

export const ReadMore = ({ content, index }: ReadMoreProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleReadMore = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  useEffect(() => {
    if (!isExpanded && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [isExpanded]);

  useEffect(() => {
    const content_html = document.querySelector(`#content_${index.toString()}`);
    const firstChild = content_html?.firstElementChild as HTMLElement;

    if (firstChild) {
      firstChild.style.cursor = "pointer";
      const handleClick = () => {
        toggleReadMore();
      };
      firstChild.addEventListener("click", handleClick);

      return () => {
        firstChild.removeEventListener("click", handleClick);
      };
    }
  }, [content, toggleReadMore, index]);

  return (
    <div className="relative">
      <div className="flex">
        <div
          ref={contentRef}
          className={`relative flex-1 ${
            isExpanded
              ? "max-h-[500px] overflow-y-auto"
              : "max-h-72 overflow-hidden"
          }`}
          style={{ scrollbarWidth: "thin", scrollbarColor: "#888 #e0e0e0" }}
        >
          <div
            id={`content_${index.toString()}`}
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {isExpanded && content.length > 300 && (
            <div className="z-10 relative  test-sm text-blue-400 w-full bg-white pt-1">
              <span
                role="button"
                onClick={toggleReadMore}
                className="cursor-pointer  relative"
              >
                Read Less
              </span>
            </div>
          )}

          {!isExpanded && content.length > 300 && (
            <>
              <div className="absolute bottom-0 left-0 w-full h-32 bg-gradient-to-t from-white to-transparent  flex items-end ">
                <div className="z-10 relative  test-sm text-blue-400 w-full bg-white pt-1">
                  <span
                    role="button"
                    onClick={toggleReadMore}
                    className="cursor-pointer  relative"
                  >
                    Read more
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
