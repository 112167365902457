import React, { useEffect, useState } from "react";
import { Button, TermsComponent, Textfield } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuestionnaireProfile,
  requestOTP,
} from "../../services/apiService.ts";
import { fetchProfile } from "../../store/slices/profileSlice.ts";
import { useParams } from "react-router-dom";

export const TsAndCsPage = ({ scrollToNextPage }: any) => {
  const tsAndcs = useSelector(
    (state: any) => state?.questionnaire_data?.questionnaire?.terms ?? ""
  );

  const dispatch = useDispatch();

  const [checkedStates, setCheckedStates] = useState(
    [...tsAndcs?.filter((term: any) => term.contents != null), "_"]?.map(
      () => false
    )
  );

  const [isDisabled, setIsDisabled] = useState(true);
  const [incorrectOTP, setIncorrectOTP] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.toUpperCase();

    // if the user omits a character
    if (newValue.length < inputValue.length) {
      setIncorrectOTP(false);
    }

    if (newValue.length <= 6) {
      setInputValue(newValue);
    }
  };

  const { checkId } = useParams<{ checkId: string }>();

  const handleRequestOTP = async () => {
    setIsLoading(true);
    try {
      await requestOTP(checkId, "check", dispatch);
      setCountdown(60); // Start the countdown
    } catch (error) {
      console.error("Failed to request OTP");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  const handleCheckboxChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newCheckedStates = [...checkedStates];
    newCheckedStates[index] = event.target.checked;
    setCheckedStates(newCheckedStates);
  };

  useEffect(() => {
    const checkedAll = checkedStates.every((item: boolean) => item);
    const inputValid = inputValue.length > 0;
    setIsDisabled(!(inputValid && checkedAll));
  }, [checkedStates, inputValue]);

  const nextPage = () => {
    getQuestionnaireProfile("check", checkId, inputValue)
      .then((response) => {
        if (response) {
          dispatch(fetchProfile(response));
          setIncorrectOTP(false);
          scrollToNextPage();
        }
      })
      .catch((error) => {
        console.error("Incorrect OTP:", error);
        setIncorrectOTP(true);
      });
  };

  const eraseText = () => {
    setInputValue("");
    setIncorrectOTP(false);
  };

  return (
    <div className="flex items-center justify-center pt-10">
      <div className="w-full space-y-6">
        <div className="space-y-4">
          <TermsComponent
            terms={tsAndcs}
            checkedStates={checkedStates}
            onCheckboxChange={handleCheckboxChange}
          />
        </div>
        <div className="w-full  p-5 gap-2.5 rounded border-4 border-wr_blue bg-wr_blue text-white">
          <div className="flex flex-col items-center justify-center space-y-2">
            <p className="font-inter text-sm font-normal leading-4 text-left">
              A one time pass code has been sent to your email address in order
              to confirm your identity before submitting your response.
            </p>
            <div className="justify-start  bottom-5 sm:bottom-10 md:bottom-10 lg:bottom-10 right-3 sm:left-28 md:left-28 lg:left-28 ">
              {!isLoading && (
                <h1
                  className={`font-inter relative bottom-3 sm:bottom-0  text-xs sm:text-sm md:text-sm lg:text-sm font-normal leading-[16.94px] text-left underline ${
                    isLoading || countdown > 0
                      ? "cursor-not-allowed opacity-50"
                      : "cursor-pointer"
                  }`}
                  onClick={() => {
                    if (!isLoading && countdown === 0) {
                      handleRequestOTP();
                    }
                  }}
                >
                  Request New Code
                </h1>
              )}

              {isLoading && (
                <div className="spinner pt-4 relative bottom-3 right-6 sm:bottom-0 text-black text-xs sm:text-sm md:text-sm lg:text-sm">
                  Loading...
                </div>
              )}
              {countdown > 0 && (
                <div className="font-inter  text-xs sm:text-sm md:text-sm lg:text-sm font-normal pt-40 sm:pt-4 md:pt-4 lg:pt-4  text-center">
                  <div className="">You can request </div>
                  <div className="">a new code in </div>
                  <div className="">
                    <span className="text-azure">{countdown}</span> seconds
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Textfield
          label="One Time Pass Code"
          value={inputValue}
          size="w-full"
          error={incorrectOTP ? "Incorrect OTP, please try again" : ""}
          eraseText={eraseText}
          handleChangeInfo={handleInputChange}
        />
        <Button
          label="Begin Right to Work Check"
          size="w-full"
          disabled={isDisabled}
          onClick={nextPage}
          bgColor="#243745"
        />
      </div>
    </div>
  );
};
