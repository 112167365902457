import React, { useRef, useState } from "react";
import {
  InputField,
  InputFieldProps,
} from "../form-field/input-field/InputField";
import DatePicker, { DatePickerProps } from "./DatePicker";
import { formatDate, isValidDate } from "../../../utils/utils";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { cn } from "../../../utils/tailwindMerger";
import useClickOutside from "../../../hooks/useClickOutSide";

type DateFieldProps = Omit<InputFieldProps, "defaultValue" | "value"> &
  Omit<
    DatePickerProps,
    "onChange" | "defaultValue" | "value" | "selectedDate" | "setSelectedDate"
  > & {
    inputClassName?: string;
    datePickerClassName?: string;
    className?: string;
    initialValue?: Date;
    value: Date;
    onDateChange: (date: Date) => void;
  };

/**
 * Renders a date field component with an input field and a date picker.
 *
 * @component
 * @example
 * ```tsx
 * <DateField
 *   inputClassName="custom-input"
 *   onChange={handleInputChange}
 *   value={selectedDate}
 *   className="date-field"
 *   initialValue={initialDate}
 *   datePickerClassName="custom-date-picker"
 *   onDateChange={handleDateChange}
 * />
 * ```
 *
 * @param {DateFieldProps} props - The component props.
 * @param {string} props.inputClassName - The class name for the input field.
 * @param {Function} props.onChange - The event handler for input change.
 * @param {Date} props.value - The selected date value.
 * @param {string} props.className - The class name for the date field component.
 * @param {Date} props.initialValue - The initial date value.
 * @param {string} props.datePickerClassName - The class name for the date picker.
 * @param {Function} props.onDateChange - The event handler for date change.
 * @returns {JSX.Element} The rendered DateField component.
 */
export const DateField = ({
  inputClassName,

  onChange,
  value,
  className,
  initialValue,
  datePickerClassName,
  onDateChange,
  ...props
}: DateFieldProps) => {
  const { ...inputFieldProps } = props;
  const { ...datePickerProps } = props;
  const [showDatePicker, setShowDatePicker] = useState(false);
  const datePickerRef = useRef<HTMLDivElement>(null);
  useClickOutside(datePickerRef, () => setShowDatePicker(false));
  const [inputValue, setInputValue] = useState<string>(
    formatDate(initialValue || value)
  );
  const dateValue = isValidDate(formatDate(initialValue || value) ?? "")
    ? new Date(value ?? "")
    : undefined;
  const today = dateValue ?? new Date();
  const [selectedDate, setSelectedDate] = useState<Date>(dateValue ?? today);

  /// Handle input change event to update the date picker value and selected date state based on the input value format and validity ///
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    // Update the input value state
    setInputValue(newValue);

    // If the user is clearing the input or the input is empty, don't execute further
    if (newValue === "" || newValue.length < inputValue.length) return;

    // Regular expressions to match the supported date formats
    const formats = [
      /^\d{2}-\d{2}-\d{4}$/, // DD-MM-YYYY
      /^\d{4}-\d{2}-\d{2}$/, // YYYY-MM-DD
      /^\d{2}\/\d{2}\/\d{4}$/, // DD/MM/YYYY
      /^\d{4}\/\d{2}\/\d{2}$/, // YYYY/MM/DD
    ];

    // Check if the input matches any of the formats
    const isValidFormat = formats.some((format) => format.test(newValue));
    if (!isValidFormat) return;

    // Convert the input to a Date object based on the matching format
    let parts, parsedDate;
    if (formats[0].test(newValue) || formats[2].test(newValue)) {
      // Handle DD-MM-YYYY or DD/MM/YYYY
      parts = newValue.split(/[-/]/);
      parsedDate = new Date(+parts[2], +parts[1] - 1, +parts[0]);
    } else if (formats[1].test(newValue) || formats[3].test(newValue)) {
      // Handle YYYY-MM-DD or YYYY/MM/DD
      parts = newValue.split(/[-/]/);
      parsedDate = new Date(+parts[0], +parts[1] - 1, +parts[2]);
    }

    if (parsedDate && !isNaN(parsedDate.getTime())) {
      const formattedDate = formatDate(parsedDate);

      setSelectedDate(new Date(formattedDate));
      onDateChange(new Date(formattedDate));
    }
  };

  return (
    <div className={cn("flex flex-col gap-y-2 relative w-full ", className)}>
      <InputField
        onClick={() => setShowDatePicker((prev) => !prev)}
        icon={faCalendarAlt}
        onIconClick={() => setShowDatePicker((prev) => !prev)}
        className={cn("w-full", inputClassName)}
        {...inputFieldProps}
        handleChange={onInputChange}
      />
      {showDatePicker && (
        <div
          ref={datePickerRef}
          className={cn("absolute top-10 bottom-0 z-[90]", datePickerClassName)}
        >
          {" "}
          <DatePicker
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            value={
              initialValue ? initialValue.toDateString() : value.toDateString()
            }
            onDateChange={(date: Date) => {
              const formattedDate = formatDate(date);
              setInputValue(formattedDate);
              onDateChange(date);
              setShowDatePicker(false);
            }}
            {...datePickerProps}
          />
        </div>
      )}
    </div>
  );
};
