import { Checkbox } from "../checkbox/Checkbox.tsx";
import { ReadMore } from "../read-more/ReadMore.tsx";
import { privacy_policy } from "./privacy-policy.ts";

interface Term {
  contents: string;
  assertion: string;
}

interface TermsComponentProps {
  terms: Term[];
  checkedStates: any;
  onCheckboxChange: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

export const TermsComponent = ({
  terms,
  checkedStates,
  onCheckboxChange,
}: TermsComponentProps) => {
  return (
    <div className="space-y-6">
      <div key={``}>
        <div className="space-y-4 max-w-none prose prose-headings:text-xl leading-6 font-inter max-sm:mb-4 mb-4">
          <ReadMore index={terms.length} content={privacy_policy} />
        </div>

        <Checkbox
          id="privacy_policy"
          checked={checkedStates[terms.length]}
          label={
            <span
              dangerouslySetInnerHTML={{
                __html:
                  "<div>I consent to the  <a href='https://www.fragomen.com/privacy-policies-notices-and-statements.html' class='text-primary' target='blank'>Fragomen Privacy Policy</a></div>",
              }}
            />
          }
          checkboxStyle="acknowledgement"
          onChange={(e) => {
            onCheckboxChange(terms.length, e);
          }}
        />
      </div>
      <h1 className=" text-[#333333] text-xl font-inter font-bold">
        General Terms and Conditions
      </h1>
      {terms
        ?.filter((term) => term.contents != null)
        .map((term, index) => (
          <div key={`tnc-${index}`}>
            <div className="space-y-4 max-w-none prose prose-headings:text-xl leading-6 font-inter max-sm:mb-4 mb-4">
              <ReadMore index={index} content={term.contents} />
            </div>

            {term.assertion && (
              <Checkbox
                id={index.toString()}
                checked={checkedStates[index]}
                label={
                  <span dangerouslySetInnerHTML={{ __html: term.assertion }} />
                }
                checkboxStyle="acknowledgement"
                onChange={(e) => {
                  onCheckboxChange(index, e);
                }}
              />
            )}
          </div>
        ))}
    </div>
  );
};
