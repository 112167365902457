import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./redux-hooks.ts";
import { setDocCaptureData } from "../store/slices/identityDocCapture.ts";
import { setRequiredDocs } from "../store/slices/uploadedNonIdDocsSlice.ts";
import { setStatus } from "src/store/slices/statusSlice.ts";
import { updateForm } from "src/store/slices/questionnaireFormSlice.ts";

interface UseJurisdiction {
  jurisdiction: Jurisdiction;
  setShowSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  updateFormValues: (formData?: Partial<FormState>) => void;
}

export const useJurisdiction = ({
  jurisdiction,
  setShowSubmit,
  updateFormValues,
}: UseJurisdiction) => {
  // Component state
  const [showIsCitizenQuestion, setShowIsCitizenQuestion] = useState(true);
  const [isCitizen, setIsCitizen] = useState(false);
  const [showStatusCategories, setShowStatusCategories] = useState(false);
  const statusData = useAppSelector((state) => state.status);
  //const formData = useAppSelector((state) => state.questionnaire_form);

  // Data state
  const [workStatus, setWorkStatus] = useState<StatusType[] | null>(null);
  const [documentCollectors, setDocumentCollectors] = useState<
    DocumentCollector[] | null
  >(null);
  const [dataCollectors, setDataCollectors] = useState<DataCollector[] | null>(
    null,
  );
  const [statusCategories, setStatusCategories] = useState<
    StatusCategory[] | null
  >(null);
  const citizenship = jurisdiction?.statusCategories?.find(
    (status) => status.isCitizenship,
  );

  // Selected options
  const [selectedStatusCategory, setSelectedStatusCategory] =
    useState<StatusCategory | null>(null);
  const [selectedStatusType, setSelectedStatusType] =
    useState<StatusType | null>(null);

  // Handlers
  const handleIsCitizen = (isCitizen: boolean) => {
    // setIsCitizenQuestion(false);
    setShowStatusCategories(true);
    setSelectedStatusCategory(null);
    setWorkStatus(null);
    setIsCitizen(isCitizen);
    if (isCitizen && citizenship) {
      setSelectedStatusCategory(citizenship);
      setStatusCategories([citizenship]);
      setWorkStatus(citizenship.statusTypes);
    }

    if (!isCitizen)
      setStatusCategories(jurisdiction?.statusCategories?.slice());
  };

  const dispatch = useAppDispatch();

  const selectStatus = (
    statusCategory: StatusCategory | null,
    statusType: any | null,
  ) => {
    if (statusType) {
      const collectors: DocumentCollector[] = statusType.documentCollectors;
      const dataCollectors = statusType.dataCollectors;
      const nonIdentityCollectors = collectors.filter(
        (collector) =>
          !collector.config.authenticate && collector.config.required,
      );
      setDocumentCollectors(collectors || []);
      dispatch(setRequiredDocs(nonIdentityCollectors || []));
      setDataCollectors(dataCollectors || []);
      setSelectedStatusType(statusType);
      setWorkStatus([statusType]);

      dispatch(
        setStatus({
          statusCategory: selectedStatusCategory!,
          statusType: statusType,
        }),
      );

      // updateFormValues({
      //   statusCategory: selectedStatusCategory!,
      //   statusType: statusType,
      // });
    }

    if (statusCategory) {
      const updatedCategories = statusCategories?.map((cat) => {
        if (cat._id === statusCategory._id) {
          return { ...cat, iscitizenship: !cat.isCitizenship };
        } else {
          return { ...cat, iscitizenship: false };
        }
      });
      if (updatedCategories) {
        resetStates();
        setStatusCategories([statusCategory]);
        setSelectedStatusCategory(statusCategory);
        setWorkStatus(statusCategory.statusTypes);
      }
    }
  };

  const resetStatus = (type: "work" | "category") => {
    if (type === "category") {
      resetStates();
      setStatusCategories(jurisdiction?.statusCategories?.slice());
    }

    if (type === "work") {
      const statusTypes = selectedStatusCategory?.statusTypes;
      resetStates(true);
      if (statusTypes) setWorkStatus(statusTypes);
    }
  };

  const resetStates = (isWorkStatus: boolean = false) => {
    if (!isWorkStatus) {
      setStatusCategories(null);
      setSelectedStatusCategory(null);
      setWorkStatus(null);
    }
    setSelectedStatusType(null);
    setDocumentCollectors(null);
    dispatch(setRequiredDocs([]));
    setDataCollectors(null);
    setShowSubmit(false);
    // setIsCitizenQuestion(false);
  };

  const handleRequiredDocs = () => {
    console.log("ID capture modal open");
    dispatch(
      setDocCaptureData({
        docCaptureModalOpen: true,
      }),
    );
  };

  const handleDataCollection = (key: string, value: string) => {
    setShowSubmit(true);

    updateFormValues({
      bratwursttype: value,
      jurisdiction,
      statusCategory: selectedStatusCategory as StatusCategory,
      statusType: selectedStatusType as StatusType,
    });
  };

  // const handleVevoCheck = (key: string, value: string) => {
  //   updateFormValues({
  //     [key]: value,
  //   });

  //   console.log("Test", formData);
  // };

  // Set initial state
  useEffect(() => {
    if (citizenship) {
      setSelectedStatusCategory(citizenship);
      setStatusCategories([citizenship]);
    } else setStatusCategories(jurisdiction.statusCategories?.slice());
  }, [jurisdiction.statusCategories, citizenship]);

  useEffect(() => {
    const citizenCategories = jurisdiction.statusCategories?.filter(
      (cat) => cat.isCitizenship,
    );
    const nonCitizenCategories = jurisdiction.statusCategories?.filter(
      (cat) => !cat.isCitizenship,
    );
    if (jurisdiction.statusCategories.length > 0) {
      if (citizenCategories?.length === 0) {
        setIsCitizen(false);
        setShowIsCitizenQuestion(false);
        setShowStatusCategories(true);
      }
      if (nonCitizenCategories?.length === 0) {
        setIsCitizen(true);
        setShowIsCitizenQuestion(false);
        setShowStatusCategories(true);
      }
    }
  }, [statusCategories, jurisdiction]);

  useEffect(() => {
    dispatch(
      updateForm({ key: "statusCategory", value: statusData.statusCategory }),
    );
    dispatch(updateForm({ key: "statusType", value: statusData.statusType }));
  }, [dispatch, statusData]);

  return {
    isCitizen,
    dataCollectors,
    documentCollectors,
    handleDataCollection,
    handleIsCitizen,
    handleRequiredDocs,
    selectStatus,
    resetStatus,
    selectedStatusCategory,
    setDocumentCollectors,
    selectedStatusType,
    showIsCitizenQuestion,
    statusCategories,
    workStatus,
    updateFormValues,
    citizenship,
    showStatusCategories,
    // handleVevoCheck,
  };
};
