import React from "react";
import { Button } from "../button/Button.tsx";
import { Button as OutLineButton } from "@simplecitizen/gdl-react-ui-components";
import {
  removeIdentityDoc,
  setDocCaptureData,
} from "../../../store/slices/identityDocCapture.ts";
import { useDispatch } from "react-redux";
// import { formatDate, parseDate, parseDateToString } from "src/utils/utils.ts";
import { removeDoc } from "../../../store/slices/uploadedNonIdDocsSlice.ts";

export interface DocumentCardProps {
  title: string;
  isOptional: boolean;
  isPassportPhoto: boolean;
  setDocumentCollectors: React.Dispatch<
    React.SetStateAction<DocumentCollector[] | null>
  >;
  isIdentityDocument: boolean;
  document?: Document | boolean;
  setSelectedDocument: React.Dispatch<
    React.SetStateAction<DocumentCollector | null>
  >;
  setSelectedIDDocument: React.Dispatch<
    React.SetStateAction<DocumentCollector | null>
  >;
  docNumber?: string;
  docIssueCountry?: string;
  selectedDocumentId: string;
  documentType?: string;
  uploadDocument?: () => void;
  changeSelection?: () => void;
  removeDocument?: () => void;
  replaceDocument?: () => void;
  doc: DocumentCollector;
}

export const DocumentCard = ({
  title = "Untitled Document",
  isOptional,
  setDocumentCollectors,
  isPassportPhoto,
  isIdentityDocument,
  document,
  docNumber,
  docIssueCountry,
  selectedDocumentId,
  setSelectedDocument,
  setSelectedIDDocument,
  documentType,
  uploadDocument,
  changeSelection,
  // removeDocument,
  replaceDocument,
  doc,
}: DocumentCardProps) => {
  const dispatch = useDispatch();

  const removeDocument = () => {
    setDocumentCollectors((currState) => {
      const newState = [...currState!];
      const docIndex = newState.findIndex((item) => item._id === doc._id);
      newState[docIndex] = {
        ...newState[docIndex],
        nonIdentityDocuments: undefined,
        identityDocuments: undefined,
      };

      return newState;
    });
  };

  return (
    <div className="w-full flex flex-col p-5 gap-4 self-stretch rounded-md border-2 border-[#ededed] bg-[#ededed] _bg-[var(--Neutrals-Nimbus,#F8F9F9)] relative">
      <div className="flex flex-col items-center gap-2">
        {/* Title and Document Info Section */}

        {doc.nonIdentityDocuments && (
          <>
            <h3 className="text-[var(--Neutrals-Monsoon)] text-sm font-semibold uppercase leading-[18px] font-inter text-center">
              {title}
              {!isOptional && (
                <span className="text-red font-bold ml-1 font-inter uppercase">
                  *
                </span>
              )}
            </h3>
            {
              <div className="flex flex-col items-center">
                <p className="text-[var(--Neutrals-Monsoon)] text-sm leading-[18px] font-semibold uppercase font-inter">
                  Document Number:{" "}
                  {doc.nonIdentityDocuments.documentNumber || "-"}
                </p>
                <p className="text-[var(--Neutrals-Monsoon)] text-sm font-normal leading-[18px] font-semibold uppercase leading-[18px] font-inter">
                  Issue Country:{" "}
                  {doc.nonIdentityDocuments.documentIssueCountry || "-"}
                </p>
                <p className="text-[var(--Neutrals-Monsoon)] text-sm leading-[18px] font-semibold uppercase font-inter">
                  Expiry date:{" "}
                  {doc.nonIdentityDocuments.documentExpiryDate
                    ? doc.nonIdentityDocuments.documentExpiryDate
                    : "-"}
                </p>
              </div>
            }
          </>
        )}
        {doc.identityDocuments && (
          <>
            <h3 className="text-[var(--Neutrals-Monsoon)] text-sm font-semibold uppercase leading-[18px] font-inter text-center">
              {title}
              {!isOptional && (
                <span className="text-red font-bold ml-1 font-inter uppercase">
                  *
                </span>
              )}
            </h3>
            {
              <div className="flex flex-col items-center">
                <p className="text-[var(--Neutrals-Monsoon)] text-sm leading-[18px] font-semibold uppercase font-inter">
                  Document Number: {doc.identityDocuments.documentNumber || "-"}
                </p>
                <p className="text-[var(--Neutrals-Monsoon)] text-sm font-normal leading-[18px] font-semibold uppercase leading-[18px] font-inter">
                  Issue Country:{" "}
                  {doc.identityDocuments.documentIssueCountry || "-"}
                </p>
                <p className="text-[var(--Neutrals-Monsoon)] text-sm font-normal leading-[18px] font-semibold uppercase leading-[18px] font-inter">
                  Expiry date: {doc.identityDocuments.documentExpiryDate || "-"}
                </p>
              </div>
            }
          </>
        )}

        {!doc.nonIdentityDocuments && !doc.identityDocuments && (
          <h3 className="text-[var(--Neutrals-Monsoon)] text-sm font-semibold uppercase leading-[18px] font-inter text-center">
            {title}
            {!isOptional && (
              <span className="text-red font-bold ml-1 font-inter uppercase">
                *
              </span>
            )}
          </h3>
        )}
        {/* Buttons Section */}
        {!doc.identityDocuments && !doc.nonIdentityDocuments ? (
          <Button
            type="button"
            size="w-full  capitalize"
            label={`Upload ${title}`}
            onClick={() => {
              doc._subtype === "default"
                ? setSelectedDocument(doc)
                : dispatch(setDocCaptureData({ selectedIDDocument: doc }));
              uploadDocument && uploadDocument();
            }}
          />
        ) : (
          <>
            {selectedDocumentId ? (
              <Button
                type="button"
                size="w-full "
                label={"Change Selection"}
                onClick={changeSelection}
              />
            ) : (
              <>
                <Button
                  type="button"
                  size="w-full "
                  label={"Replace Upload"}
                  onClick={() => {
                    doc._subtype === "default"
                      ? setSelectedDocument(doc)
                      : dispatch(
                          setDocCaptureData({ selectedIDDocument: doc })
                        );
                    replaceDocument && replaceDocument();
                  }}
                />

                {(doc.nonIdentityDocuments || doc.identityDocuments) && (
                  <OutLineButton
                    type="button"
                    color="red"
                    variant="outline"
                    className="w-full py-5 rounded-lg"
                    onClick={() => {
                      removeDocument();
                      if (doc.nonIdentityDocuments) {
                        dispatch(removeDoc(doc));
                      }
                      if (doc.identityDocuments) {
                        dispatch(removeIdentityDoc(doc));
                      }
                      dispatch(setDocCaptureData({ selectedIDDocument: null }));
                    }}
                  >
                    Remove Document
                  </OutLineButton>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DocumentCard;
