import { isValid } from "date-fns";

export const scrollToElement = (
  elementRef: React.RefObject<HTMLDivElement>
) => {
  if (elementRef.current) {
    elementRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

export const formatString = (str: string): string => {
  return str
    ?.replace(/[-_]/g, " ")
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Add a space between camelCase words
    .toLowerCase();
};

export function isValidDate(dateString: string): boolean {
  // Try to create a Date object from the string
  const date = new Date(dateString);

  // Check if the date is valid
  return !isNaN(date.getTime());
}

export const formatDate = (dateArg: Date) => {
  const date = new Date(dateArg);

  const year = date?.getFullYear();
  const month = String(date?.getMonth() + 1).padStart(2, "0");
  const day = String(date?.getDate()).padStart(2, "0");
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export function truncateText(text: string, maxLength: number) {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + "...";
}

export function parseDate(dateString: string) {
  // Split the date string into day, month, and year
  const [day, month, year] = dateString.split("/");

  // Ensure all parts are valid
  if (!day || !month || !year) {
    throw new Error("Invalid date format. Expected format is DD/MM/YYYY.");
  }

  // Create and return the Date object
  return new Date(`${year}-${month}-${day}`);
}

export function parseDateToString(dateString: string) {
  // Split the date string into day, month, and year
  const [year, month, day] = dateString.split("/");

  // Ensure all parts are valid
  if (!day || !month || !year) {
    throw new Error("Invalid date format. Expected format is DD/MM/YYYY.");
  }

  // Create and return the Date object
  return `${year}/${day}/${month}`;
}

export function formatToValidDate(dateString: string) {
  // Split the date string into day, month, and year
  const [day, month, year] = dateString.split("/");

  // Ensure all parts are valid
  if (!day || !month || !year) {
    throw new Error("Invalid date format. Expected format is DD/MM/YYYY.");
  }

  // Create and return the Date object
  return `${year}-${month}-${day}`;
}

export function universalDateFormatter(dateString: string) {
  // Split the date string into day, month, and year
  if (!dateString) {
    return "";
  }
  const [year, month, day] = dateString.split("/");

  // Ensure all parts are valid
  if (!day || !month || !year) {
    throw new Error("Invalid date format. Expected format is YYYY-MM-DD.");
  }

  const edgeBrowsweDate = `${day}/${month}/${year}`;
  const chromeDate = `${year}/${month}/${day}`;

  if (isValid(new Date(edgeBrowsweDate))) {
    return edgeBrowsweDate;
  }
  return chromeDate;
}
