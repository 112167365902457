import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "src/hooks/redux-hooks";
import {
  Button,
  Checkbox,
  Jurisdiction,
  Title,
  WorkrightProfile,
} from "src/components";
import { useQuestionnaireForm } from "src/hooks/useQuestionnaireForm";
import { useParams } from "react-router-dom";
import { submitCheck } from "src/services/apiService";
import { useSelector } from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

export interface QuestionnairePageProps {
  scrollToNextPage: () => void;
}

export const QuestionnairePage: React.FC<QuestionnairePageProps> = ({
  scrollToNextPage,
}) => {
  const jurisdictionValues = useAppSelector(
    (state) => state.questionnaire_form.jurisdiction
  );
  const { checkId } = useParams<{ checkId: string }>();
  const uploadedNonIdDocs = useAppSelector((state) => state.non_id_docs.docs);
  const selectedStatusType = useAppSelector(
    (state) => state.non_id_docs.selectedStatusType
  );
  const selectedStatusCategory = useAppSelector(
    (state) => state.non_id_docs.selectedStatusCategory
  );

  const statusCategory = useAppSelector((state) => state.status.statusCategory);

  const statusType = useAppSelector((state) => state.status.statusType);
  const uploadedIdDocs = useAppSelector(
    (state) => state.identity_doc_capture.uploadedDocs
  );
  const requiredDocs = useAppSelector(
    (state) => state.non_id_docs.requiredDocs
  );

  const questionnaire_data = useAppSelector(
    (state: any) => state?.questionnaire_data?.questionnaire
  );

  const questionnaire_form = useAppSelector(
    (state: any) => state?.questionnaire_form
  );

  const submitDocIDs = useSelector(
    (state: any) => state?.identity_doc_capture?.submittedIDs
  );

  const [selectedDocument, setSelectedDocument] =
    useState<DocumentCollector | null>(null);
  console.log({ questionnaire_data });

  const [selectedIDDocument, setSelectedIDDocument] =
    useState<DocumentCollector | null>(null);

  const [isAustralian, setIsAustralian] = useState(false);
  useEffect(() => {
    if (jurisdictionValues) {
      setIsAustralian(jurisdictionValues.code === "AU");
    }
  }, [jurisdictionValues]);

  const {
    registerWithClearErrors,
    errors,
    formData,
    showSubmit,
    setshowSubmit,
    updateFormValues,
  } = useQuestionnaireForm(isAustralian);
  const { firstName, middleName, lastName } = formData;
  const profile = {
    firstName,
    middleName,
    lastName,
  };
  const jurisdiction = formData.jurisdiction;
  const submitRef = useRef<HTMLDivElement>(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVevoSuccess, setIsVevoSuccess] = useState(false);
  const [isVevoError, setIsVevoError] = useState(false);
  const [docsError, setDocsError] = useState<string | null>(null);

  const handleVevoCheck = async () => {
    try {
      setIsVevoSuccess(true);
      setshowSubmit(true);
      setIsAustralian(false);
    } catch (error) {
      setIsVevoError(true);
    }
  };

  // const doc_collector = useAppSelector(
  //   (state: any) => state?.identity_doc_capture?.getDocumentCollector
  // );
  // const status = useAppSelector((state) => state.status);
  const [submitted, setSubmitted] = useState(false);

  let endpointLoading = useSelector(
      (state: any) => state?.identity_doc_capture?.endpointLoading
  );

  // const documents = Array.isArray(doc_collector)
  //   ? doc_collector.reduce(
  //       (acc: { [x: string]: any }, collector: { key: string | number }) => {
  //         acc[collector.key] = questionnaire_data?._id;
  //         return acc;
  //       },
  //       {}
  //     )
  //   : {};
  //
  // console.log("documents", documents);

  // const uploadedIdDocsIds = uploadedIdDocs
  //   ? uploadedIdDocs.map((doc: any) => ({
  //       [doc._id]: doc.doc_key,
  //     }))
  //   : [];

  const allDocs = (uploadedIdDocs ?? []).concat(uploadedNonIdDocs);
  const uploadedRequiredDocs = allDocs.filter(
    (document: any) => document?.config?.required
  );

  useEffect(() => {
    if (uploadedRequiredDocs.length === 0 || requiredDocs?.length === 0) {
      setDocsError("");
    }
  }, [requiredDocs?.length, uploadedRequiredDocs.length]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDocsError(null);
    if (isAustralian) {
      handleVevoCheck();
      // setIsAustralian(false);
    }
    const uploadedNonIdDocsIds = uploadedNonIdDocs
      ? uploadedNonIdDocs.map((doc: any) => ({
          [doc._id]: doc.doc_key,
        }))
      : [];

    const uploadedIdDocsIds = uploadedIdDocs
      ? uploadedIdDocs.map((doc: any) => ({
          [doc._id]: doc.doc_key,
        }))
      : [];
    const allDocs = (uploadedIdDocs ?? []).concat(uploadedNonIdDocs);
    const uploadedRequiredDocs = allDocs.filter(
      (document: any) => document?.config?.required
    );
    const docsToSendArray = uploadedNonIdDocsIds.concat(uploadedIdDocsIds);
    const docsToSend = docsToSendArray?.reduce(
      (acc: { [x: string]: any }, doc: { [x: string]: any }) => {
        acc = { ...acc, ...doc };
        return acc;
      },
      {},
    );

    const transformedDocIDs = Array.isArray(submitDocIDs)
      ? submitDocIDs.reduce(
          (
            acc: { [x: string]: unknown },
            obj: { [s: string]: unknown } | ArrayLike<unknown>,
          ) => {
            const [key, value] = Object.entries(obj)[0];
            acc[key] = value;
            return acc;
          },
          {},
        )
      : {};

    const filteredDocsToSend = Object.fromEntries(
      Object.entries(docsToSend).filter(([key, value]) => value !== undefined)
    );

    let sendToResponseDocs = {
      ...transformedDocIDs,
      ...filteredDocsToSend,
      ...(Object.keys(submitDocIDs).length > 0 ? submitDocIDs : {})
    };

    const requiredDocs = selectedStatusType?.documentCollectors.filter(
      (document) => document.config.required,
    );
    const isRequiredDocsUploaded =
      uploadedRequiredDocs.length >= (requiredDocs?.length ?? 0);

    if (showSubmit) {
      setIsSubmitting(true);
      try {
        const check_data = {
          _type: questionnaire_data._type,
          // pk: "string",
          // sk: "string",
          statusCategoryId: selectedStatusCategory?._id || statusCategory?._id,
          statusTypeId: selectedStatusType?._id || statusType?._id,
          documents: sendToResponseDocs,
          data: questionnaire_form,
        };

        if (!isRequiredDocsUploaded) {
          setDocsError("Please upload all required documents");
          return;
        }

        setDocsError("");

        setSubmitted(true);

        await submitCheck(checkId, check_data);
        scrollToNextPage();
      } catch (error) {
        console.error("Error Submitting Form", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setIsChecked(checked);
  };

  useEffect(() => {
    if (showSubmit && submitRef.current && !isAustralian) {
      // scrollToElement(submitRef);
    } else {
      setIsChecked(false);
    }
  }, [showSubmit, isAustralian]);

  // useEffect(() => {
  //   if (selectedDocument) {
  //     dispatch(setDocCaptureData({ nonIDModalOpen: true }));
  //   }
  // }, [dispatch, selectedDocument]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue =
        "Are you sure you want to leave? You will need to resubmit the questionnaire.";
    };
    if (!submitted) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [submitted]);

  return (
    <div className="flex items-center justify-center pt-1">
      <form
        onSubmit={onSubmit}
        className={`w-full md:min-w-[455px] md:max-w-[455px] py-4 flex flex-col gap-3`}
      >
        {profile && (
          <WorkrightProfile
            register={registerWithClearErrors}
            profile={profile}
            errors={errors}
          />
        )}

        {jurisdiction && (
          <Jurisdiction
            setSelectedDocument={setSelectedDocument}
            setSelectedIDDocument={setSelectedIDDocument}
            selectedDocument={selectedDocument}
            selectedIDDocument={selectedIDDocument}
            isVevoSuccess={isVevoSuccess}
            isVevoError={isVevoError}
            setIsVevoSuccess={setIsVevoSuccess}
            isAustralian={isAustralian}
            setIsAustralian={setIsAustralian}
            register={registerWithClearErrors}
            jurisdiction={jurisdiction}
            errors={errors}
            formData={formData}
            setShowSubmit={setshowSubmit}
            updateFormValues={updateFormValues}
            checkId={checkId}
          />
        )}
        {docsError && (
          <p className="text-red-500 text-sm font-normal leading-4 text-left">
            {docsError}
          </p>
        )}

        {endpointLoading  && (
            <div className="fixed top-0 left-0 w-screen h-screen bg-gray-500/50 z-10 flex justify-center items-center">
              <div className="bg-white w-96 h-96 flex justify-center items-center rounded-lg overflow-hidden">
                <div className="flex items-center justify-center h-screen md:min-h-[70vh]">
                  <FontAwesomeIcon
                      icon={faSpinner}
                      className="text-[10rem] text-deepWater"
                      pulse
                  />
                </div>
              </div>
            </div>
        )}

        {showSubmit && (
            <div
                ref={submitRef}
                className="text-left min-w-full md:min-w-[455px] md:max-w-[455px]"
          >
            <Title text="Submit" />
            <p className="font-inter text-sm font-normal leading-4 text-left pb-3">
              The above information is true and accurate to the best of your
              knowledge. Any misrepresentation to your work status may result in
              a delay of confirming your right to work and if you have not
              already started work, may result in a delay to starting your role.
            </p>
            <Checkbox
              id="submit"
              checked={isChecked}
              label={"I agree to the above"}
              checkboxStyle="acknowledgement"
              onChange={handleTerms}
            />
            <Button
              label={isSubmitting ? "Submitting..." : "Submit Response"}
              size="w-full mt-3"
              disabled={!isChecked || isSubmitting}
              type="submit"
            />
          </div>
        )}
      </form>
    </div>
  );
};
