import { ComponentProps } from "react";
import ContentWrapper from "../content-wrapper/ContentWrapper";
import { cn } from "../../../utils/tailwindMerger";
import { useAppSelector } from "src/hooks/redux-hooks";

type FooterProps = {
  className?: string;
} & ComponentProps<"div">;

export const Footer = ({ className, ...props }: FooterProps) => {
  const logo = useAppSelector(
    (state: any) =>
      state?.questionnaire_data?.questionnaire?.client?.branding?.logo || ""
  );

  const client_name: string = useAppSelector(
    (state: any) => state?.questionnaire_data?.questionnaire?.client?.name || ""
  );
  return (
    <footer
      className={cn("w-full h-full flex flex-col justify-end pb-8", className)}
      {...props}
    >
      <ContentWrapper className="flex md:justify-end  ">
        <div className="flex items-end">
          <div className="flex h-full flex-col items-end">
            <div className=" w-full max-sm:text-sm">
              <span>Powered by</span>
            </div>
            {/* destkop image */}
            <div className="md:flex hidden h-full">
              <img width={130} src="/images/frag.png" alt="Footer" />
              <div className="w-[1.5px] mx-2 h-full bg-gray-300"></div>
            </div>
            {/* mobile image */}
            <div className="flex md:hidden h-full">
              <img width={100} src="/images/frag.png" alt="Footer" />
              <div className="w-[1.5px] mx-2 h-full bg-gray-300"></div>
            </div>
          </div>
          {/* destkop image */}
          {logo !== "" &&
            client_name.toLowerCase().replace(" ", "").trim() !== "workright" &&
            client_name.toLowerCase().replace(" ", "").trim() !==
              "fragomen" && (
              <div className="md:block hidden">
                <img
                  width={120}
                  src="/images/workRight_Logo.png"
                  alt="Footer"
                  className=""
                />
              </div>
            )}

          {/* mobile image */}
          {logo !== "" &&
            client_name.toLowerCase().replace(" ", "").trim() !== "workright" &&
            client_name.toLowerCase().replace(" ", "").trim() !==
              "fragomen" && (
              <div className="md:hidden block">
                <img
                  width={100}
                  src="/images/workRight_Logo.png"
                  alt="Footer"
                  className=""
                />
              </div>
            )}
        </div>
      </ContentWrapper>
    </footer>
  );
};
