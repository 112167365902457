import React from "react";
import { useEffect } from "react";
// import { useRouter } from 'next/navigation';
// import { useIdentityDocumentService } from '../../../services/identity-document.service';
// import { useDocumentUploadService } from '../../../services/document-upload.service'
import { DocumentStatus } from "../../../models/identity-document.enum";
import { EventEmitter } from "events";
import { setDocCaptureData } from "../../../../store/slices/identityDocCapture.ts";
import { useDispatch, useSelector } from "react-redux";
import { IdentityDocumentUploadSteps } from "../identity-document-upload-steps/IdentityDocumentUploadSteps.tsx";
//import getDoc from "../identity-document-upload-test/get-document-response-no-data.json"
// import {IdentityDocumentUploadSteps} from "../identity-document-upload-steps/identity-document-upload-steps";

export interface IdentityDocumentUploaderProps {
  identityDocumentID: string;
  setDocumentCollectors: React.Dispatch<
    React.SetStateAction<DocumentCollector[] | null>
  >;
}

const DOCUMENT_TYPE = "document";
const DOCUMENT_SUBTYPE = "identity_document";

export const IdentityDocumentUploader = ({
  identityDocumentID,
  setDocumentCollectors,
}: IdentityDocumentUploaderProps) => {
  // const [uploadProgress, setUploadProgress] = useState<number>(0);
  // const [documentCollectorId, setDocumentCollectorId] = useState();
  // const [applicationId, setApplicationId] = useState();
  // const [documentHolderId, setDocumentHolderId] = useState();
  // const [identityDocumentId, setIdentityDocumentId] = useState();
  // const [relationships, setRelationships] = useState<
  //     { type: string; id: string }[]
  // >([]);
  const dispatch = useDispatch();

  const documentCollectorID = useSelector(
    (state: any) =>
      state?.identity_doc_capture?.getDocumentCollector[0]._id ?? "",
  );

  useEffect(() => {
    const draftDocument = {
      _id: documentCollectorID,
      _type: DOCUMENT_TYPE,
      _subtype: DOCUMENT_SUBTYPE,
      status: DocumentStatus.Draft,
    };

    dispatch(
      setDocCaptureData({
        draftDocument: draftDocument,
      }),
    );

    //const service = useIdentityDocumentService();
    // service.createDocument(draftDocument)
    //     .then((document) => {
    //         let identityDocument = document as UnprocessableIdentityDocument;
    //         setIdentityDocumentId(identityDocument?.document?.id ?? "");
    //     })
    //     .catch((error: any) => {
    //         console.error('Error creating document:', error);
    //     });
  }, [documentCollectorID, dispatch]);

  // useEffect(() => {
  //     if (router.query) {
  //         const { documentCollectorID, applicationDocumentID, documentHolderId } = router.query;
  //         if (typeof documentCollectorID === 'string') {
  //             setDocumentCollectorId(documentCollectorID);
  //         }
  //         if (typeof applicationDocumentID === 'string') {
  //             setApplicationId(applicationDocumentID);
  //         }
  //         if (typeof documentHolderID === 'string') {
  //             setDocumentHolderId(documentHolderID);
  //         }
  //     }
  // }, [router.query]);

  const documentUploadCompleteEvent = new EventEmitter();

  const onDocumentUploadComplete = (uploadedDocumentId: string) => {
    documentUploadCompleteEvent.emit("documentUploaded", uploadedDocumentId);
  };

  const notifyDocumentUploaded = () => {
    // const documentInfo = {
    //     documentId: identityDocumentID,
    //     documentCollectorID,
    // };
    //useDocumentUploadService().notifyDocumentUploaded(documentInfo);
  };

  const closeUploadModal = async () => {
    dispatch(setDocCaptureData({ docCaptureModalOpen: false }));
    dispatch(setDocCaptureData({ selectedIDDocument: null }));
    dispatch(setDocCaptureData({ nonIDModalOpen: false }));
  };

  const onDocumentStored = (uploadedDocumentId: string) => {
    onDocumentUploadComplete(uploadedDocumentId);
    notifyDocumentUploaded();
    closeUploadModal();
  };

  const onUploadCancelled = () => {
    closeUploadModal();
  };

  return (
    <div className="fixed inset-0 z-[99] flex items-center justify-center bg-gray-500 bg-opacity-75 transition-opacity">
      <div className="box-border w-80 sm:w-[45rem] md:w-[45rem] rounded-2xl max-h-screen flex items-center justify-center">
        <IdentityDocumentUploadSteps
          setDocumentCollectors={setDocumentCollectors}
          documentCollectorId={documentCollectorID}
          identityDocumentId={identityDocumentID}
          onDocumentStored={onDocumentStored}
          onUploadCancelled={onUploadCancelled}
        />
      </div>
    </div>
  );
};
